const Response = {
    error: {
      400: 'Something went wrong!! Please try again',
    },
    success: {
      created: 'Created Successfully !!',
      updated: 'Updated Successfully !!',
      deleted: 'Deleted Successfully !!',
    },
  };
  
  module.exports = Response;
  