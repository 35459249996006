import axios from 'axios';
import Config from '../config/config';
import PNotify from "pnotify/dist/es/PNotify";
import Response from '../utils/response';
const { axiosInstance: { baseUrl } } = Config;

const orders = {
    state: {
        orders: [],
        singleOrder: {},
        orderStatus: [],
        paymentDetails: [],
        shipments: [],
        shippingRates: [],
        unshippedOrders: [],
        singleShipment: {}
    },
    reducers: {
        fetchOrders(state, data) {
            return {
                ...state,
                orders: data
            }
        },
        fetchSingleOrder(state, data) {
            return {
                ...state,
                singleOrder: data,
            }
        },
        fetchOrderStatus(state, data) {
            return {
                ...state,
                orderStatus: data
            }
        },
        fetchPaymentDetails(state, data) {
            return {
                ...state,
                paymentDetails: data
            };
        },
        fetchShipments(state, data) {
            return {
                ...state,
                shipments: data
            };
        },
        fetchShippingRates(state, data) {
            return {
                ...state,
                shippingRates: data
            };
        },
        fetchUnshippedOrders(state, data) {
            return {
                ...state,
                unshippedOrders: data
            }
        },
        fetchShipmentDetails(state, data) {
            return {
                ...state,
                singleShipment: data
            }
        }
    },
    effects: {
        async getOrders() {
            try {
                const result = await axios.get(`${baseUrl}/api/orders`);
                this.fetchOrders(result.data);

            } catch (error) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getSingleOrderByID(id) {
            try {
                const result = await axios.get(`${baseUrl}/api/getOrderByOrderId/${id}`);
                this.fetchSingleOrder(result.data);

            } catch (error) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getOrdersByOrderNumber(order_number) {
            try {
                const { data } = await axios.get(`${baseUrl}/api/getOrdersByOrderNumber/${order_number}`);
                this.fetchSingleOrder(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                })
            }
            const { data } = await axios.get(`${baseUrl}/api/getOrdersByOrderNumber/${order_number}`);
            this.fetchSingleOrder(data);
        },

        async getOrderAllStatus() {
            try {
                const result = await axios.get(`${baseUrl}/api/ordersStatus`);
                this.fetchOrderStatus(result.data);
            } catch (error) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateOrderInformation(payload) {
            try {
                await axios.put(`${baseUrl}/api/updateOrderDetails/${payload.id}`, payload.form);
                PNotify.success({
                    title: "Success",
                    text: Response.success.updated
                });
                this.getSingleOrderByID(payload.id);
            } catch (error) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getPaymentDetails() {
            try {
                const { data } = await axios.get(`${baseUrl}/api/paymentDetails`);
                this.fetchPaymentDetails(data);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async createShipment(data) {
            try {
                const res = await axios.post(`${baseUrl}/api/createShipment`, data);
                PNotify.success({
                    title: "Success",
                    text: "Shipment Created Successfully !"
                });

            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                })
            }
        },
        async getAllShipments() {
            try {
                const res = await axios.get(`${baseUrl}/api/allShipments`);
                this.fetchShipments(res.data.data);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getShipmentDetailsByOrderId(orderId) {
            try {
                const { data } = await axios.get(`${baseUrl}/api/shipmentDetailsByOrderId?order_id=${orderId}`);
                this.fetchShipmentDetails(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                })
            }
        },
        async updateShipmentAddress(data) {
            try {
                await axios.post(`${baseUrl}/api/updateShipmentAddress`, data);
                PNotify.success({
                    title: "Updated",
                    text: "Address updated successfully !"
                });
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                })
            }
        },
        async cancelShipmentById(id) {
            try {
                const data = {
                    ids: [id]
                };
                const res = await axios.post(`${baseUrl}/api/cancelShipment`, data);
                PNotify.success({
                    title: "Success",
                    text: "Order Cancelled Successfully !"
                })
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                })
            }
        },
        async findShippingRates({ delivery_postcode, weight, cod }) {
            try {
                const res = await axios.get(`${baseUrl}/api/shippingCharges/?delivery_postcode=${delivery_postcode}&weight=${weight}&cod=${cod}`);
                this.fetchShippingRates(res.data);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getUnshippedOrders() {
            try {
                const { data } = await axios.get(`${baseUrl}/api/unshippedOrders`);
                this.fetchUnshippedOrders(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                })
            }

        }
    }
}


export default orders;