import Config from '../config/config';
import SimpleCrypto from "simple-crypto-js";
const cookie = require('js-cookie');

const { secretKey, keywords } = Config.cookies;
const Crypto = new SimpleCrypto(secretKey);

/**
* add data into Cookie
*/
export function addCookie(key, value) {
  const encrypted = Crypto.encrypt(JSON.stringify(value));
  cookie.set(keywords[key], encrypted, { path: '/' });
}

/**
* get data from Cookie
*/
export function getCookie(key) {
  const data = cookie.get(keywords[key]);
  if (data) {
    const decrypted = Crypto.decrypt(data);
    return decrypted;
  }
  return null;
}

/**
* remove data in Cookie
*/
export function removeCookie(key) {
  cookie.remove(keywords[key], { path: '/' });
}

/**
* remove item in Cookie
*/
export function removeItem(key, id) {
  const data = cookie.get(keywords[key]);
  let decrypted = Crypto.decrypt(data)
  decrypted = decrypted.filter(item => item.id !== id);
  const encrypted = Crypto.encrypt(JSON.stringify(decrypted));
  cookie.set(keywords[key], encrypted, { path: '/' });
}
