import axios from 'axios';
import PNotify from "pnotify/dist/es/PNotify";
import Config from '../config/config';
import Response from '../utils/response';
const { baseUrl: baseURL } = Config.axiosInstance;


const promoCodes = {
    state: {
        offers: [],
        offer: {},
        offersByType: [],
        offersByCategory: []
    },
    reducers: {
        fetchOffers(state, data) {
            return {
                ...state,
                offers: data
            };
        },
        fetchOffer(state, data) {
            return {
                ...state,
                offer: data
            };
        },
        fetchOffersByType(state, data) {
            return {
                ...state,
                offersByType: data
            };
        }

    },
    effects: {
        async getOffers() {
            try {
                const result = await axios.get(`${baseURL}/api/offers`);
                this.fetchOffers(result.data);

            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },

        async addOffer(data) {
            data.category_id = parseInt(data.category_id);
            data.offer_type_id = parseInt(data.offer_type_id);
            try {
                await axios.post(`${baseURL}/api/addOffer`, data);
                this.getOffers();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateOffer(data) {
            data.category_id = parseInt(data.category_id);
            data.offer_type_id = parseInt(data.offer_type_id);
            try {
                await axios.put(`${baseURL}/api/updateOffer`, data);
                this.getOffers();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateOfferStatus(data) {
            try {
                await axios.put(`${baseURL}/api/promocodeStatus`, data);
                this.getOffers();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async deleteOffer(id) {
            try {
                await axios.delete(`${baseURL}/api/deleteOffer/${id}`);
                this.getOffers();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getOffersByType() {
            try {
                const { data } = await axios.get(`${baseURL}/api/offersByTypes`);
                this.fetchOffersByType(data);

            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },

        async getOfferById(id) {
            try {
                const result = await axios.get(`${baseURL}/api/getOfferById/${id}`);
                this.fetchOffer(result.data);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getOffersByTypeId(id) {
            try {
                await axios.get(`${baseURL}/api/getOffersByTypeId/${id}`);
                this.getOffers();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
    }
};

export default promoCodes;