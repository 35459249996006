import axios from 'axios';
import Config from '../config/config';
import Response from '../utils/response';
import PNotify from "pnotify/dist/es/PNotify";
const baseURL = Config.axiosInstance.baseUrl;

const category = {
    state: {
        categories: [],
        parentCategories: [],
        filtersByCategory: [],
        filterTypes: [],
        categoryId: '',
    },

    reducers: {
        fetchCategories(state, items) {
            return {
                ...state,
                categories: items
            };
        },
        fetchParentCategories(state, data) {
            return {
                ...state,
                parentCategories: data
            }
        },
        fetchFiltersByCategory(state, data) {
            return {
                ...state,
                filtersByCategory: data
            };
        },
        fetchFilterTypes(state, data) {
            return {
                ...state,
                filterTypes: data
            };
        },
        fetchCategoryId(state, id) {
            return {
                ...state,
                categoryId: id
            }
        }
    },

    effects: {
        async getAllCategories() {
            try {
                const result = await axios.get(`${baseURL}/api/getAllCategories`);
                const { data } = result;
                this.fetchCategories(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addFilterCategory({category_id, filter_id}) {
            try {
                await axios.post(`${baseURL}/api/filterCategory`, { category_id, filter_id});
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                })
            }

        },
        async deleteFilterCategory(id) {
            try {
                await axios.delete(`${baseURL}/api/filterCategory/${id}`);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                })
            }
        },
        async getParentCategories() {
            try {
                const { data } = await axios.get(`${baseURL}/api/parentCategories`);
                this.fetchParentCategories(data);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addCategory(payload) {
            try {
                const { data: { id } } = await axios.post(`${baseURL}/api/category`, payload);
                PNotify.success({
                    title: "Success",
                    text: Response.success.created
                });

                this.getAllCategories();
                this.fetchCategoryId(id);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateCategory(payload) {
            try {
                await axios.put(`${baseURL}/api/category`, payload);
                PNotify.success({
                    title: "Success",
                    text: Response.success.updated
                });
                this.getAllCategories();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async deleteCategory(id) {
            try {
                await axios.delete(`${baseURL}/api/category/${id}`);
                this.getAllCategories();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getFiltersByCategoryId(id) {
            try {
                const { data } = await axios.get(`${baseURL}/api/filtersByCategory/${id}`);
                this.fetchFiltersByCategory(data);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getFilterTypes() {
            try {
                const { data } = await axios.get(`${baseURL}/api/filterTypes`);
                this.fetchFilterTypes(data);
            }
            catch (err) {
                PNotify.error({
                    title: 'Error',
                    text: Response.error[400]
                })
            }
        }
    }
};

export default category;
