import axios from 'axios';
import Config from '../config/config';
import Response from '../utils/response';
import PNotify from "pnotify/dist/es/PNotify";

const { baseUrl } = Config.axiosInstance;

const filters = {
    state: {
        filterTypes: [],
        filterOptions: []
    },
    reducers: {
        fetchFilterTypes(state, data) {
            return {
                ...state,
                filterTypes: data
            };
        },
        fetchFilterOptions(state, data) {
            return {
                ...state,
                filterOptions: data
            }
        }
    },
    effects: {
        async getFilterTypes() {
            try {
                const { data } = await axios.get(`${baseUrl}/api/filterTypes`);
                this.fetchFilterTypes(data);

            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getFilterOptions() {
            try {
                const { data } = await axios.get(`${baseUrl}/api/filterOptions`);
                this.fetchFilterOptions(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addFilterType(payload) {
            try {
                await axios.post(`${baseUrl}/api/filterType`, payload);
                this.getFilterTypes();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addFilterOption(payload) {
            try {
                await axios.post(`${baseUrl}/api/filterOption`, payload);
                this.getFilterOptions();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateFilterType(payload) {
            try {
                await axios.put(`${baseUrl}/api/filterType`, payload);
                this.getFilterTypes();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateFilterOption(payload) {
            try {
                await axios.put(`${baseUrl}/api/filterOption`, payload);
                this.getFilterOptions();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async deleteFilterType(id) {
            try {
                await axios.delete(`${baseUrl}/api/filterType/${id}`);
                this.getFilterTypes();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async deleteFilterOption(id) {
            try {
                await axios.delete(`${baseUrl}/api/filterOption/${id}`);
                this.getFilterOptions();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        }
    }
}
export default filters;