import { init } from '@rematch/core';
import * as models from '../models';
import { logger } from '../utils/actionLogger';

export default function configureStore() {
  const store = init({
    models,
    redux: {
      middlewares: [logger]
    },
  });
  return store;
};