import axios from 'axios';
import Config from '../config/config';
import PNotify from "pnotify/dist/es/PNotify";
const { baseUrl } = Config.axiosInstance;

const gstDetails = {
    state: {
        gstInfo: {}
    },
    reducers: {
        fetchGstInfo(state, data) {
            return {
                ...state,
                gstInfo: data
            };
        }
    },
    effects: {
        async getGstDetailsById(id) {
            try {
                const res = await axios.get(`${baseUrl}/api/getGstDetailsById/${id}`);
                this.fetchGstInfo(res.data);
            }
            catch (err) {
                PNotify.error({
                    title: 'Error',
                    text: Response.error[400]
                });
            }
        },
        async updateGstDetailsById(payload) {
            try {
                await axios.put(`${baseUrl}/api/updateGstDetailsById`, payload);
                PNotify.success({
                    title:'Success',
                    text:'Details updated successfully !'
                })
            }
            catch (err) {
                PNotify.error({
                    title: 'Error',
                    text: Response.error[400]
                })
            }
        }
    }
}
export default gstDetails;