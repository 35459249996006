import axios from 'axios';
import Config from '../config/config';
import PNotify from "pnotify/dist/es/PNotify";
import Response from '../utils/response';
const { axiosInstance: { baseUrl } } = Config;

const inventory = {
    state: {
        inventories: [],
        singleInventory: {}
    },

    reducers: {
        fetchInventories(state, data) {
            return {
                ...state,
                inventories: data
            }
        },
        fetchInventoryByProductID(state, data) {
            return {
                ...state,
                singleInventory: data
            }
        }
    },
    effects: {
        async getInventories() {
            try {
                const result = await axios.get(`${baseUrl}/api/getAllInventories`);
                this.fetchInventories(result.data);
            } catch (error) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addInventory(payload) {
            try {
                await axios.post(`${baseUrl}/api/addInventory`, payload);
                this.getInventories();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getInventoryByProductID(product_id) {
            try {
                const result = await axios.get(`${baseUrl}/api/getInventoryByID/${product_id}`);
                this.fetchInventoryByProductID(result.data);
            } catch (error) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async deleteInventoryById(id) {
            try {
                await axios.delete(`${baseUrl}/api/deleteInventoryByID/${id}`);
                this.getInventories();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateInventoryByProductID(payload) {
            try {
                const { product_id, ...updatedPayload } = payload;
                await axios.put(`${baseUrl}/api/updateInventoryDetailsByProductID/${product_id}`, updatedPayload);
                this.getInventories();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }

        }
    }
}

export default inventory;