import axios from 'axios';
import Config from '../config/config';
import Response from '../utils/response';
import PNotify from "pnotify/dist/es/PNotify";
const { baseUrl } = Config.axiosInstance;
const specialOffers = {
    state: {
        isLoading: false,
        error: null,
        offers: []
    },
    reducers: {
        loading(state) {
            return {
                ...state,
                isLoading: true
            };
        },
        loadingOff(state) {
            return {
                ...state,
                isLoading: false
            };
        },
        fetchOffers(state, offers) {
            return {
                ...state,
                offers
            };
        }
    },
    effects: {
        async getSpecialOffers(adminValue) {
            let url = `${baseUrl}/api/specialoffers`;
            if (adminValue) {
                url = `${baseUrl}/api/specialoffers?admin=${adminValue}`
            }

            try {
                const { data } = await axios.get(url);
                this.fetchOffers(data);
            }
            catch (err) {
                console.log(err)
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async deleteSpecialOffer(id) {
            try {
                await axios.delete(`${baseUrl}/api/specialoffers/${id}`);
                this.getSpecialOffers(1);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addSpecialOffer(data) {
            try {
                await axios.post(`${baseUrl}/api/specialoffers`, data);
                this.getSpecialOffers(1);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateSpecialOffer(data) {
            try {
                await axios.put(`${baseUrl}/api/specialoffers`, data);
                this.getSpecialOffers(1);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        }
    }
};
export default specialOffers;