import axios from 'axios';
import Config from '../config/config';
import Response from '../utils/response';
import PNotify from "pnotify/dist/es/PNotify";

const baseURL = Config.axiosInstance.baseUrl;

const creative = {
    state: {
        creatives: [],
        creativeTypes: [],
        awsImages: [],
    },

    reducers: {
        fetchCreatives(state, items) {
            return {
                ...state,
                creatives: items
            };
        },
        fetchCreativeTypes(state, items) {
            return {
                ...state,
                creativeTypes: items
            };
        },
        fetchAwsImages(state, data) {
            return {
                ...state,
                awsImages: data
            };
        }
    },

    effects: {
        async getAllCreatives() {
            try {
                const result = await axios.get(`${baseURL}/api/promoGalleries`);
                const { data } = result;
                this.fetchCreatives(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addCreative(payload) {
            try {
                await axios.post(`${baseURL}/api/addPromoGallery`, payload);
                PNotify.success({
                    title: "Success",
                    text: Response.success.created
                });
                this.getAllCreatives();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async uploadToAws(data) {
            try {
                const response = await axios.post(`${baseURL}/api/uploadImage`, data, {
                    headers: {
                        "Content-Type": 'multipart/form-data'
                    }
                });
                if (200 === response.status) {
                    const data = response.data.fileUrlArray;
                    await this.fetchAwsImages(data);
                    PNotify.success({
                        title: "Success",
                        text: "File upload successful"
                    });
                }
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: "Cannot upload file. Please try again!"
                })
            }
        },
        async updateCreative({ id, ...data }) {
            try {
                data.promo_type_id = parseInt(data.promo_type_id, 10);
                await axios.put(`${baseURL}/api/updatePromoGallery/${id}`, data);
                this.getAllCreatives();
            }
            catch (err) {
                PNotify.success({
                    title: "Success",
                    text: Response.success.updated
                })
            }
        },
        async deleteCreative(id) {
            try {
                await axios.delete(`${baseURL}/api/promoGallery/${id}`);
                this.getAllCreatives();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },

        async getAllCreativeTypes() {
            try {
                const result = await axios.get(`${baseURL}/api/promoTypes`);
                const { data } = result;
                this.fetchCreativeTypes(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addCreativeType(payload) {
            try {
                await axios.post(`${baseURL}/api/addPromoType`, payload);
                PNotify.success({
                    title: "Success",
                    text: Response.success.created
                });
                this.getAllCreativeTypes();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateCreativeType({ id, ...data }) {
            try {
                await axios.put(`${baseURL}/api/updatePromoType/${id}`, data);
                PNotify.success({
                    title: "Success",
                    text: Response.success.updated
                });
                this.getAllCreativeTypes();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async deleteCreativeType(id) {
            try {
                await axios.delete(`${baseURL}/api/promoType/${id}`);
                this.getAllCreativeTypes();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
    }
};

export default creative;