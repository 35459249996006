import axios from 'axios';
import Config from '../config/config';
import PNotify from "pnotify/dist/es/PNotify";
import Response from '../utils/response';
const { axiosInstance: { baseUrl } } = Config;
const ordersStatus = {
    state: {
        ordersStatus: [],
        orderStatus: {}
    },
    reducers: {
        fetchOrdersStatus(state, data) {
            return {
                ...state,
                ordersStatus: data
            };
        },
        fetchOrderStatus(state, data) {
            return {
                ...state,
                orderStatus: data
            };
        }
    },
    effects: {
        async getOrdersStatus() {
            try {
                const { data } = await axios.get(`${baseUrl}/api/ordersStatus`);
                this.fetchOrdersStatus(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getOrderStatus(id) {
            try {
                const { data } = await axios.get(`${baseUrl}/api/orderStatus/${id}`);
                this.fetchOrderStatus(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async updateOrderStatus(data) {
            try {
                await axios.put(`${baseUrl}/api/orderStatus`, data);
                this.getOrdersStatus();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async deleteOrderStatus(id) {
            try {
                await axios.delete(`${baseUrl}/api/orderStatus/${id}`);
                this.getOrdersStatus();

            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addOrderStatus(data) {
            try {
                await axios.post(`${baseUrl}/api/addOrderStatus`, data);
                this.getOrdersStatus();
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        }
    }


};
export default ordersStatus;