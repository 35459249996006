import dateformat from 'dateformat';
import axios from 'axios';
import Config from '../config/config'
const { axiosInstance: { baseUrl } } = Config;

export const logger = store => next => async action => {
    const type = action.type
    const timestamp = dateformat(new Date())
    await next(action);
    const users = store.getState().users;
    const id = users.user.id
    const name = users.user.name
    const payload = {
        type,
        timestamp,
        name,
        id
    }
    // await axios.post(`${baseUrl}/api/addlogs`, payload).then(() => console.log("Success")).catch((err) => console.log(err))
}

