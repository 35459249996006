import axios from 'axios';
import Config from '../config/config';
import Response from '../utils/response';
import PNotify from "pnotify/dist/es/PNotify";
const {baseUrl} = Config.axiosInstance;
const roles = {
    state: {
        usersRoles: []
    },
    reducers: {
        fetchUsersRoles(state, data) {
            return { ...state, usersRoles: data }
        }
    },
    effects: {
        async getUsersRoles() {
            try {
                const result = await axios.get(`${baseUrl}/api/roles`);
                this.fetchUsersRoles(result.data);
            }
            catch (err) {
                PNotify.error({
                    title:"Error",
                    text:Response.error[400]
                });
            }
        },
        async addUserRole(data) {
            try {
                await axios.post(`${baseUrl}/api/addRole`, data);
                this.getUsersRoles();
            }
            catch (err) {
                PNotify.error({
                    title:"Error",
                    text:Response.error[400]
                });
            }
        },
        async updateUserRoleStatus(data) {
            try {
                await axios.put(`${baseUrl}/api/userRoleStatus`,data);
                this.getUsersRoles();
            }
            catch (err) {
                PNotify.error({
                    title:"Error",
                    text:Response.error[400]
                });
            }
        },
        async updateUserRole(data) {
            try {
                await axios.put(`${baseUrl}/api/updateRole`,data);
                this.getUsersRoles();
            }
            catch (err) {
                PNotify.error({
                    title:"Error",
                    text:Response.error[400]
                });
            }
        },
        async deleteUserRole(id) {
            try{
                 await axios.delete(`${baseUrl}/api/role/${id}`);
            this.getUsersRoles();
            }
            catch (err) {
                PNotify.error({
                    title:"Error",
                    text:Response.error[400]
                });
            }
        },
    }

};
export default roles;