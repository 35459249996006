import axios from 'axios';
import Config from '../config/config';
import Response from '../utils/response';
import PNotify from "pnotify/dist/es/PNotify";
const { baseUrl } = Config.axiosInstance;
const deals = {
    state: {
        isLoading: false,
        error: null,
        deals: []
    },
    reducers: {
        loading(state) {
            return {
                ...state,
                isLoading: true
            };
        },
        loadingOff(state) {
            return {
                ...state,
                isLoading: false
            };
        },
        fetchDeals(state, deals) {
            return {
                ...state,
                deals
            };
        }
    },
    effects: {
        async getDeals() {
            try {
                const { data } = await axios.get(`${baseUrl}/api/deals`);
                this.fetchDeals(data);
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async deleteDealById(id) {
            try {
                await axios.delete(`${baseUrl}/api/deals/${id}`);
                this.getDeals();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async addDeal(data) {
            try {
                await axios.post(`${baseUrl}/api/deals`, data);
                this.getDeals();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async editDeal(data) {
            try {
                await axios.put(`${baseUrl}/api/deals`, data);
                this.getDeals();
            }
            catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        }
    }
};
export default deals;