import axios from 'axios';
import Config from '../config/config';
import PNotify from 'pnotify/dist/es/PNotify';
import Response from '../utils/response';
import { getCookie, addCookie } from '../utils/cookie';
import _ from 'lodash';

const baseURL = Config.axiosInstance.baseUrl;

const users = {
	state: {
		isRedirect: false,
		isLoggedIn: false,
		user: {},
		users: [],
		isLoading: false,
		isEditing: false,
		error: null,
		success: null,
	},

	reducers: {
		loading(state) {
			return {
				...state,
				isLoading: true,
			};
		},
		fetchUser(state, data) {
			return {
				...state,
				user: data,
			};
		},
		fetchUsers(state, item) {
			return {
				...state,
				users: item,
				isLoading: false,
			};
		},
		fetchCookies(state) {
			return {
				...state,
				isLoggedIn: getCookie('isLoggedIn'),
				user: getCookie('user'),
			};
		},
		submitSuccess(state, item) {
			return {
				...state,
				user: item,
				isRedirect: true,
			};
		},
	},

	effects: {
		async adminLogin(payload) {
			try {
				const result = await axios.post(`${baseURL}/api/adminlogin`, payload);
				this.submitSuccess(result.data);
				PNotify.success({
					title: 'Success',
					text: 'Login Successfull!',
				});
				const { user } = result.data;
				await this.updateCookies(user);
			} catch (err) {
				PNotify.error({
					title: 'Error',
					text: Response.error[400],
				});
			}
		},

		getCookies() {
			this.fetchCookies();
		},

		clearCookies() {
			addCookie('isLoggedIn', false);
			addCookie('user', {});
			this.fetchCookies();
		},

		async updateCookies(payload) {
			const user = payload;
			addCookie('isLoggedIn', true);
			addCookie('user', user);
		},

		async getUsers() {
			try {
				const result = await axios.get(`${baseURL}/api/users`);
				const { data } = result;
				this.fetchUsers(data);
			} catch (err) {
				PNotify.error({
					title: 'Error',
					text: Response.error[400],
				});
			}
		},
		async addUser(data) {
			try {
				await axios.post(`${baseURL}/api/user`, data);
				this.getUsers();
			}
			catch (err) {
				PNotify.error({
					title: "Error",
					text: Response.error[400]
				});
			}
		},
		async updateUserStatus(data) {
			try {
				await axios.put(`${baseURL}/api/userStatus`, data);
				this.getUsers();
			}
			catch (err) {
				PNotify.error({
					title: "Error",
					text: Response.error[400]
				});
			}
		},
		async updateUser(data) {
			data.contact_number = parseInt(data.contact_number);
			try {
				await axios.put(`${baseURL}/api/updateUser`, data);
				this.getUsers();
			}
			catch (err) {
				PNotify.error({
					title: "Error",
					text: Response.error[400]
				});
			}
		},
		async deleteUser(id) {
			try {
				await axios.delete(`${baseURL}/api/user/${id}`);
				this.getUsers();
			}
			catch (err) {
				PNotify.error({
					title: "Error",
					text: Response.error[400]
				});
			}
		},
		async getUserDetail(id) {
			try {
				const result = await axios.get(`${baseURL}/api/user/${id}`);
				this.fetchUser(result.data);
			} catch (err) {
				PNotify.error({
					title: 'Error',
					text: Response.error[400],
				});
			}
		},
		async getCustomers(payload) {
			const params = _.pickBy(
				{
					role: payload,
				},
				(value) => value !== ''
			);
			try {
				const result = await axios.get(`${baseURL}/api/users`, { params });
				this.fetchUsers(result.data);
			} catch (error) {
				PNotify.error({
					title: 'Error',
					text: Response.error[400],
				});
			}
		},
		async getCustomerDetails(id) {
			try {
				const result = await axios.get(
					`${baseURL}/api/getCustomerDetails/${id}`
				);
				this.fetchUser(result.data);
				PNotify.success({
					title: 'Success',
					text: 'Fetched Customer Details!',
				});
			} catch (error) {
				PNotify.error({
					title: 'Error',
					text: Response.error[400],
				});
			}
		},
	},
};
export default users;
