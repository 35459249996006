const navigation = {
    state: {
        isOpen: [],
        isTrigger: [],
        defaultPath: '/signIn',
        basename: '/top-ten-electronics', // only at build time to set, like /datta-able
        layout: 'vertical', // vertical, horizontal
        preLayout: '', // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
        collapseMenu: false, // mini-menu
        layoutType: 'menu-dark', // menu-dark, menu-light, dark
        navIconColor: false,
        headerBackColor: 'header-default', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
        navBackColor: 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
        navBrandColor: 'brand-default', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
        navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
        rtlLayout: false,
        navFixedLayout: true,
        headerFixedLayout: false,
        boxLayout: false,
        navDropdownIcon: 'style1', // style1, style2, style3
        navListIcon: 'style1', // style1, style2, style3, style4, style5, style6
        navActiveListColor: 'active-default', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
        navListTitleColor: 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
        navListTitleHide: false,
        configBlock: false,
        layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
        layout6BackSize: '', // used only for pre-layout = layout-6
        isFullScreen: false,
    },

    reducers: {
        RE_SET(state) {
            return {
                ...state,
                layout: state.layout,
                preLayout: state.preLayout,
                collapseMenu: state.collapseMenu,
                layoutType: state.layoutType,
                navIconColor: state.navIconColor,
                headerBackColor: state.headerBackColor,
                navBackColor: state.navBackColor,
                navBrandColor: state.navBrandColor,
                navBackImage: state.navBackImage,
                rtlLayout: state.rtlLayout,
                navFixedLayout: state.navFixedLayout,
                headerFixedLayout: state.headerFixedLayout,
                boxLayout: state.boxLayout,
                navDropdownIcon: state.navDropdownIcon,
                navListIcon: state.navListIcon,
                navActiveListColor: state.navActiveListColor,
                navListTitleColor: state.navListTitleColor,
                navListTitleHide: state.navListTitleHide,
                layout6Background: state.layout6Background
            };
        },
        COLLAPSEMENU(state) {
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        },
        COLLAPSETOGGLE(state, data) {
            return {
                ...state,
                isOpen: data.open,
                isTrigger: data.trigger
            };
        },
        FULLSCREEN(state) {
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        },
        FULLSCREENEXIT(state) {
            return {
                ...state,
                isFullScreen: false
            };
        },
        CHANGELAYOUT() {

        },
        LAYOUTTYPE(state) {
            return {
                ...state,
                layoutType: state.layoutType,
                navBackColor: (state.layoutType === 'dark' && state.navBackColor === 'navbar-default') ? 'navbar-dark' : state.navBackColor,
                navBrandColor: (state.layoutType === 'dark' && state.navBrandColor === 'brand-default') ? 'brand-dark' : state.navBrandColor,
                navBackImage: state.navBackImage,
                headerBackColor: state.headerBackColor
            };
        },
        NAVBACKCOLOR(state) {
            return {
                ...state,
                navBackColor: state.navBackColor,
                navBackImage: state.navBackImage,
                navBrandColor: 'brand-default',
                layoutType: (state.layoutType === 'menu-light') ? 'menu-dark' : state.layoutType
            };
        },
        NAVBACKIMAGE(state) {
            return {
                ...state,
                layoutType: 'menu-dark',
                navBackImage: state.navBackImage,
                navBrandColor: '',
                navBackColor: ''
            };
        },
        NAVBRANDCOLOR(state) {
            return {
                ...state,
                navBrandColor: state.navBrandColor
            };
        },
        HEADERBACKCOLOR(state) {
            return {
                ...state,
                headerBackColor: state.headerBackColor
            };
        },
        NAVICONCOLOR(state) {
            return {
                ...state,
                navIconColor: !state.navIconColor
            };
        },
        LAYOUT6BACKGROUND() {

        },
        RTLLAYOUT(state) {
            return {
                ...state,
                rtlLayout: !state.rtlLayout
            };
        },
        NAVFIXEDLAYOUT(state) {
            return {
                ...state,
                navFixedLayout: !state.navFixedLayout
            };
        },
        HEADERFIXEDLAYOUT(state) {
            return {
                ...state,
                headerFixedLayout: !state.headerFixedLayout,
                headerBackColor: (!state.headerFixedLayout && state.headerBackColor === 'header-default') ? 'header-blue' : state.headerBackColor,
                navBrandColor: (!state.headerFixedLayout) ? 'brand-default' : state.navBrandColor
            };
        },
        BOXLAYOUT(state) {
            return {
                ...state,
                boxLayout: !state.boxLayout
            };
        },
        NAVDROPDOWNICON(state) {
            return {
                ...state,
                navDropdownIcon: state.navDropdownIcon,
            };
        },
        NAVLISTICON(state) {
            return {
                ...state,
                navListIcon: state.navListIcon,
            };
        },
        NAVACTIVELISTCOLOR(state) {
            return {
                ...state,
                navActiveListColor: state.navActiveListColor,
            };
        },
        NAVLISTTITLECOLOR(state) {
            return {
                ...state,
                navListTitleColor: state.navListTitleColor,
            };
        },
        NAVLISTTITLEHIDE(state) {
            return {
                ...state,
                navListTitleHide: !state.navListTitleHide,
            };
        },
        NAVCONTENTLEAVE(state, data) {
            return {
                ...state,
                isOpen: data.open,
                isTrigger: data.trigger,
            };
        },
        NAVCOLLAPSELEAVE(state, data) {
            return {
                ...state,
                isOpen: data.open,
                isTrigger: data.trigger,
            };
        },
        NAVCOLLAPSELEAVE1(state) {
            return {
                ...state
            };
        },
        CONFIGBLOCK(state) {
            return {
                ...state,
                configBlock: !state.configBlock,
            };
        },
        CHANGEPRELAYOUT(state) {
            return {
                ...state,
                preLayout: state.preLayout
            };
        }
    },

    effects: {
        RESET() {
            this.RE_SET();
        },

        COLLAPSE_MENU() {
            this.COLLAPSEMENU();
        },

        COLLAPSE_TOGGLE(action, { navigation: state }) {
            let trigger = [];
            let open = [];
            if (action.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.id);
                    trigger = trigger.filter(item => item !== action.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.id];
                    trigger = [...trigger, action.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.id);
                trigger = (triggerIndex === -1) ? [action.id] : [];
                open = (triggerIndex === -1) ? [action.id] : [];
            }
            this.COLLAPSETOGGLE({ open, trigger });
        },

        FULL_SCREEN() {

        },

        FULL_SCREEN_EXIT() {
            this.FULLSCREENEXIT();
        },

        CHANGE_LAYOUT() {

        },

        LAYOUT_TYPE() {

        },

        NAV_BACK_COLOR() {

        },

        NAV_BACK_IMAGE() {

        },

        NAV_BRAND_COLOR() {

        },

        HEADER_BACK_COLOR() {

        },

        NAV_ICON_COLOR() {

        },

        LAYOUT6_BACKGROUND() {

        },

        RTL_LAYOUT() {

        },

        NAV_FIXED_LAYOUT() {

        },

        HEADER_FIXED_LAYOUT() {

        },

        BOX_LAYOUT() {

        },

        NAV_DROPDOWN_ICON() {

        },

        NAV_LIST_ICON() {

        },

        NAV_ACTIVE_LIST_COLOR() {

        },

        NAV_LIST_TITLE_COLOR() {

        },

        NAV_LIST_TITLE_HIDE() {

        },

        NAV_CONTENT_LEAVE() {

        },

        NAV_COLLAPSE_LEAVE(action, { navigation: state }) {
            let trigger = [];
            let open = [];
            if (action.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.id);
                    trigger = trigger.filter(item => item !== action.id);
                }
                this.NAVCOLLAPSELEAVE({ open, trigger});
            }
            this.NAVCOLLAPSELEAVE1();
        },

        CONFIG_BLOCK() {

        },

        CHANGE_PRE_LAYOUT() {

        }
    }
};

export default navigation;
