import axios from 'axios';
import Config from '../config/config';
import PNotify from 'pnotify/dist/es/PNotify';
import Response from '../utils/response';

const { baseUrl } = Config.axiosInstance;

const productsReviews = {
    state: {
        productsReviews: [],
        productReviews: [],
        userReviews: [],
        usersReviews: [],
    },
    reducers: {
        fetchProductsReviews(state, data) {
            return {
                ...state,
                productsReviews: data
            }
        },
        fetchUserReviews(state, data) {
            return {
                ...state,
                userReviews: data
            }
        },
        fetchUsersReviews(state, data) {
            return {
                ...state,
                usersReviews: data
            }
        },
        fetchProductReviews(state, data) {
            return {
                ...state,
                productReviews: data
            }
        }
    },
    effects: {
        async getProductsReviews() {
            try {
                const { data } = await axios.get(`${baseUrl}/api/productsReviews`);
                this.fetchProductsReviews(data);

            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getProductReviewsById(product_id) {
            try {
                const { data } = await axios.get(`${baseUrl}/api/productReviewsById/${product_id}`);
                this.fetchProductReviews(data);
            } catch (err) {
                PNotify.error({
                    title: 'Error',
                    text: Response.error[400]
                });
            }
        },
        async addReview() {
            try {
                await axios.post(`${baseUrl}/api/addReview`);
                this.getProductsReviews();
            } catch (err) {
                PNotify.error({
                    title: 'Error',
                    text: Response.error[400]
                });
            }
        },
        async updateReviewById(id) {
            try {
                await axios.put(`${baseUrl}/api/updateReview/${id}`);
                this.getProductsReviews();
            } catch (err) {
                PNotify.error({
                    title: 'Error',
                    text: Response.error[400]
                });
            }
        },
        async deleteReview(id) {
            try {
                await axios.delete(`${baseUrl}/api/review/${id}`);
                this.getProductsReviews();
            } catch (err) {
                PNotify.error({
                    title: 'Error',
                    text: Response.error[400]
                });
            }
        },
        async getUsersReviews() {
            try {
                const { data } = await axios.get(`${baseUrl}/api/usersReviews`);
                this.fetchUsersReviews(data);
            } catch (err) {
                PNotify.error({
                    title: "Error",
                    text: Response.error[400]
                });
            }
        },
        async getUserReviewsById(user_id) {
            try {
                const { data } = await axios.get(`${baseUrl}/api/userReviewsById/${user_id}`);
                this.fetchUserReviews(data);
            } catch (err) {
                PNotify.error({
                    title: 'Error',
                    text: Response.error[400]
                });
            }
        }
    }
}
export default productsReviews;