const Config = {
  version: '1.0.0',
  axiosInstance: {
    baseUrl: 'https://api.toptenelectronics.in',
  },
  cookies: {
    secretKey: 'toptenelectronics',
    keywords: {
      isLoggedIn: '_iuli',
      user: '_usr',
    },
  },
  GstId: 1,
  company_details: {
    name: 'TopTen Electronics',
    address: 'Plot No 39/2, Sector – 30A, Vashi, Navi Mumbai, Thane, Maharashtra',
    phone_number: '022-62676886',
    email: 'info@toptenelectronics.in',
    terms_and_conditions:
      'Refunds will be given only if the product to be replaced is not in stock. Refunds shall be processed once the products have been received at our designated location in unused condition with the tags intact. Usually, it takes 10-15 days for refunds to reflect in your bank account.',
  },
  role: {
    customer: "user",
    admin: "user",
  },
  image_Url: {
    configurl: 'https://doiqgxrhp4iii.cloudfront.net',
  },
  allCategories: {
    parentCategoryId: 0
  },
  defaultLogoFileName: 'default.webp'
};

export default Config;
