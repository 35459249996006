import axios from 'axios';
import Config from '../config/config';
import PNotify from 'pnotify/dist/es/PNotify';
import { errMessage } from '../utils/errHandle'

const { baseUrl } = Config.axiosInstance;
const products = {
  state: {
    products: [],
    brands: [],
    productsByBrand: [],
    categories: [],
    singleProduct: {},
    filterOptions: [],
    productFilters: []
  },
  reducers: {
    fetchProducts(state, data) {
      return {
        ...state,
        products: data,
      };
    },
    fetchBrands(state, data) {
      return {
        ...state,
        brands: data
      }
    },
    fetchCategory(state, data) {
      return {
        ...state,
        categories: data
      }
    },
    fetchProductByID(state, data) {
      return {
        ...state,
        singleProduct: data
      }
    },
    fetchProductsByBrandId(state, data) {
      return {
        ...state,
        productsByBrand: data
      };
    },
    fetchFilterOptions(state, data) {
      return {
        ...state,
        filterOptions: data
      }
    },
    fetchProductFilters(state, data) {
      return {
        ...state,
        productFilters: data
      }
    }
  },

  effects: {
    async getProducts() {
      try {
        const result = await axios.get(`${baseUrl}/api/getAllProducts`);
        this.fetchProducts(result.data);
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async getProductsByCategory(data) {
      const payload = {
        category_id: data.category_id,
        publish: data.publish,
        parent_id: data.parent_id
      }
      try {
        const result = await axios.post(`${baseUrl}/api/productsByCategory`, payload);
        this.fetchProducts(result.data);
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async getBrands() {
      try {
        const result = await axios.get(`${baseUrl}/api/brands`);
        this.fetchBrands(result.data);
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async getCategories(id) {
      try {
        const result = await axios.get(`${baseUrl}/api/getCategoryHierarchy/${id}`);
        this.fetchCategory(result.data);
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async addProduct({ productGallery, optionsArr, ...payload }) {
      try {
        const { data: { id } } = await axios.post(`${baseUrl}/api/addProduct`, payload);
        await this.addProductGallery({ productGallery, caption_img: payload.title, product_id: id });
        this.addFilterProducts({ optionsArr, productId: id });
        this.getProducts();
        PNotify.success({
          title: 'Success',
          text: "Product added successfully !",
        });
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async getProductsByBrandId(id) {
      try {
        const { data } = await axios.get(`${baseUrl}/api/products?brand_id=${id}`);
        this.fetchProductsByBrandId(data);
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async getProductByID(id) {
      try {
        const result = await axios.get(`${baseUrl}/api/productInfo/${id}`);
        this.fetchProductByID(result.data);
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async updateProductByID({ id, ...data }) {
      try {
        await axios.put(`${baseUrl}/api/updateProduct/${id}`, data);
        PNotify.success({
          title: 'Success',
          text: "Product Edited successfully !",
        });
        this.getProducts();
      }
      catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async updateImportProductByID(data) {
      try {
        await axios.put(`${baseUrl}/api/updateImportProduct`, data);
      }
      catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async updateProductStatus(data) {
      try {
        await axios.put(`${baseUrl}/api/productStatus`, data);
        this.getProducts();
      }
      catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async deleteProductByID(id) {
      try {
        await axios.delete(`${baseUrl}/api/product/${id}`);
        this.getProducts()
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async addProductGallery({ productGallery, caption_img, product_id }) {
      try {
        for (let i = 0; i < productGallery.length; i++) {
          let data = {
            product_id,
            caption_img,
            image_url: productGallery[i].fileName
          };
          await axios.post(`${baseUrl}/api/addProductGallery`, data);
        }
        await this.getProductByID(product_id)
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async uploadImage(id) {
      try {
        await axios.put(`${Config.image_Url.configurl}/${id}`);
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async addFilterProducts({ optionsArr, productId }) {
      try {
        for (let i = 0; i < optionsArr.length; i++) {
          await axios.post(`${baseUrl}/api/addFilterProducts`, { filter_options_id: optionsArr[i].value, product_id: productId });
        }
      }
      catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async getFilterOptions() {
      try {
        const { data } = await axios.get(`${baseUrl}/api/filterOptions`);
        this.fetchFilterOptions(data);
      }
      catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async getFilterOptionsByProductId(id) {
      try {
        const { data } = await axios.get(`${baseUrl}/api/filterOptionsByProductId/${id}`);
        this.fetchProductFilters(data);
      }
      catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }

    },
    async deleteProductFilterById(id) {
      try {
        await axios.delete(`${baseUrl}/api/productFilter/${id}`);
      }
      catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async deleteProductGallery({ id, product_id, image_url }) {
      try {
        await axios.delete(`${baseUrl}/api/deleteImage?fileUrl=${Config.image_Url.configurl}/${image_url}`);
        await axios.delete(`${baseUrl}/api/gallary/${id}`);
        this.getProductByID(product_id);
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async updateProductGalleryById({ id, ...data }) {
      const { product_id } = data;
      try {
        await axios.put(`${baseUrl}/api/updateProductGallery/${id}`, data)
        this.getProductByID(product_id);
        PNotify.success({
          title: "Success",
          text: "Data updated successfully !"
        })
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    },
    async filterProductsByPublish(value) {
      const { data } = await axios.get(`${baseUrl}/api/getAllProducts`);
      try {
        if (value === 1) {
          this.fetchProducts(data)
        }
        if (value === 2) {
          const updatedProducts = data.filter((item) => { return item.is_published })
          this.fetchProducts(updatedProducts)
        }
        if (value === 3) {
          const updatedProducts = data.filter((item) => { return !item.is_published })
          this.fetchProducts(updatedProducts)
        }
        PNotify.success({
          title: "Success",
          text: "Data filtered successfully !"
        })
      } catch (err) {
        const { response: { status } } = err;
        if (status) {
          errMessage(status);
          return;
        };
        PNotify.error({
          title: 'Error',
          text: "Something went wrong!",
        });
      }
    }
  },
};
export default products;
