import PNotify from 'pnotify/dist/es/PNotify';

export const errMessage = (code) => {

  if (code === 400) {
    PNotify.error({
      title: 'Error',
      text: "Please fill all required fields!",
    });
    return;
  }

  else if (code === 401) {
    PNotify.error({
      title: 'Error',
      text: "Unauthorized Access!",
    });
    return;
  }

  else if (code === 500) {
    PNotify.error({
      title: 'Error',
      text: "Internal Server Error!",
    });
    return;
  }

  else {
    PNotify.error({
      title: 'Error',
      text: "Something went wrong! Please try again.",
    });
    return;
  }
}
